import React from "react";

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: "50px", fontFamily: "Arial, sans-serif", lineHeight: "1.6", textAlign: "left"}}>
      <h1>Privacy Policy for EdXcelerate</h1>
      <p><strong>Effective Date:</strong> January 1, 2025</p>
      <p>
        At EdXcelerate, we are committed to protecting the privacy and security
        of our users' data. This Privacy Policy outlines how we collect, use,
        and safeguard the information provided to us by students, schools, and
        other users of our platform. We understand the sensitive nature of
        student data and are dedicated to maintaining transparency, compliance,
        and the highest standards of data protection.
      </p>

      <h2>1. Information We Collect</h2>
      <ul>
        <li><strong>Student Information:</strong> Name, email address, school year, academic plans, courses taken, grades, and other data necessary to help students create and optimize their educational pathways.</li>
        <li><strong>School Information:</strong> Data about school course offerings, pathways, and other institutional resources.</li>
        <li><strong>Technical Data:</strong> Log data, IP addresses, and usage analytics to improve platform functionality and security.</li>
      </ul>

      <h2>2. How We Use the Information</h2>
      <ul>
        <li>Help students build, manage, and optimize their educational plans.</li>
        <li>Assist schools in creating and managing pathways and course catalogs.</li>
        <li>Provide insights and reports to schools to improve educational outcomes.</li>
        <li>Improve our platform’s features, usability, and performance.</li>
        <li>Communicate updates, support, and important notices to users.</li>
      </ul>

      <h2>3. How We Protect Your Information</h2>
      <p>
        EdXcelerate prioritizes security and privacy by implementing robust
        measures to protect data:
      </p>
      <ul>
        <li><strong>Encryption:</strong> All data is encrypted in transit and at rest using industry-standard protocols.</li>
        <li><strong>Access Controls:</strong> We employ strict access controls, ensuring only authorized personnel can access sensitive data.</li>
        <li><strong>Hosting Security:</strong> Data is stored securely on Digital Ocean servers, which comply with industry-leading security standards.</li>
        <li><strong>Django Framework:</strong> Our backend is powered by Django, which includes built-in protections against cross-site scripting (XSS), cross-site request forgery (CSRF), and SQL injection.</li>
        <li><strong>Regular Audits:</strong> Our systems undergo regular security audits and vulnerability assessments.</li>
        <li><strong>Compliance:</strong> We comply with applicable laws, such as FERPA, to ensure data privacy and security.</li>
        <li><strong>Data Minimization:</strong> We only collect and retain data necessary for providing our services.</li>
      </ul>

      <h2>4. Data Sharing and Disclosure</h2>
      <p>We do not sell, rent, or trade personal data to third parties.</p>
      <p>Unless otherwise specified, we may share data with:</p>
      <ul>
        <li><strong>Schools:</strong> Schools partnering with EdXcelerate will have access to student data relevant to their institution to support educational planning.</li>
        <li><strong>Service Providers:</strong> Trusted third-party vendors, such as Digital Ocean, who assist in providing our services are bound by confidentiality agreements and adhere to strict data protection standards.</li>
        <li><strong>Legal Compliance:</strong> If required by law, we may disclose data to comply with legal obligations.</li>
      </ul>

      <h2>5. Data Retention</h2>
      <p>
        We retain data only as long as necessary to provide our services or as
        required by applicable laws. Regular backups are performed to ensure
        data availability and integrity. Users may request data deletion at any
        time, subject to school or regulatory requirements.
      </p>

      <h2>6. User Rights</h2>
      <ul>
        <li>Access their personal information.</li>
        <li>Request corrections to their data.</li>
        <li>Request data deletion (subject to school and regulatory requirements).</li>
        <li>Opt-out of non-essential communications.</li>
      </ul>
      <p>
        To exercise these rights, please contact us at <strong>ryan@edxcelerate.com</strong>.
      </p>

      <h2>7. Schools’ Role in Privacy</h2>
      <p>
        EdXcelerate partners with schools to provide our services. Schools act
        as data controllers, and we operate as a data processor under their
        guidance. We work closely with our partner schools to ensure compliance
        with privacy regulations.
      </p>

      <h2>8. Cookies and Tracking</h2>
      <p>
        Our platform uses cookies and similar technologies to enhance user
        experience and analyze platform usage. Users can manage cookie
        preferences through their browser settings.
      </p>

      <h2>9. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy to reflect changes to our practices
        or applicable laws. Updates will be communicated through our platform
        or directly to users, as appropriate.
      </p>

      <h2>10. Contact Us</h2>
      <p>
        If you have questions or concerns about this Privacy Policy or how your
        data is handled, please contact us at: ryan@edxcelerate.com
      </p>
      <h2>Commitment to Privacy and Security</h2>
      <p>
        EdXcelerate values the trust placed in us by students, schools, and
        other stakeholders. Protecting data is at the core of our mission to
        empower educational pathways and ensure a secure environment for all
        users.
      </p>
    </div>
  );
};

export default PrivacyPolicy;