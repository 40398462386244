import './App.css';
import { useUser } from './context/UserContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import './components/styles/global.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from  './components/public/Navbar'
import Navbar2 from  './components/admin/Navbar2'
import HSNavbar2 from './components/admin/HSNavbar2';
import StudentNavbar from  './components/student/Navbar'
import AdminStudentNavbar from './components/admin/AdminStudentNavbar';
import HomePage from './components/public/HomePage'; 
import SignUp from './components/public/SignUp';
import LogIn from './components/public/Login2';
import StudentOnboarding from './components/student/StudentOnboarding';
import AdminOnboarding from './components/admin/AdminOnboarding';
import CoursePage from './components/admin/CoursePage2';
import StudentCoursePage from './components/student/CoursePage2';
import Profile from './components/admin/Profile';
import BlogPost from './components/public/BlogPost';
import PathwaysTab from './components/admin/PathwaysTab';
import StudentPathwaysTab from './components/student/PathwaysTab';
import CoursesTab from './components/admin/CoursesTab';
import PartnerCoursesTab from './components/admin/PartnerCoursesTab';
import StudentCoursesTab from './components/student/CoursesTab';
import PathwayPage from './components/admin/PathwayPage';
import StudentPathwayPage from './components/student/PathwayPage';
import FourYearPlan from './components/student/FourYearPlan';
import StudentsList from './components/admin/StudentsList';
import Test from './components/Test'
import ForgotPassword from './components/public/ForgotPassword';
import ResetPassword from './components/public/ResetPassword';
import ParentStudentsList from './components/parent/ParentStudentsList';
import ParentStudentNavbar from './components/parent/ParentStudentNavbar';
import ParentNavbar from './components/parent/ParentNavbar';
import OnboardingQuestions from './components/student/OnboardingQuestions';
import PrivacyPolicy from './components/public/PrivacyPolicy';


function App() {
  // const { user, isAuthenticated, isLoading } = useUser();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userType, setUserType] = useState(null);
  const [schoolType, setSchoolType] = useState(null);
  const [isAdminViewingAsStudent, setIsAdminViewingAsStudent] = useState(localStorage.getItem('isAdminViewingAsStudent') === 'true'); // New state
  const [isParentViewingAsStudent, setIsParentViewingAsStudent] = useState(localStorage.getItem('isParentViewingAsStudent') === 'true');


  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const token = localStorage.getItem('token');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    
    
    const fetchCurrentUser = async () => {
      try {
        const headers = {};
        if (token) {
          headers['Authorization'] = `Token ${token}`;
        }
        const cachedUser = localStorage.getItem('cachedUser');        
          const response = await axios.get(`${API_ENDPOINT}/api/current-user/`, { headers });
          localStorage.setItem('cachedUser', JSON.stringify(response.data));
          console.log('Fetched current user:', response.data);
          setIsAuthenticated(true);
          setUserType(response.data.user_type);
          setSchoolType(response.data.school.school_type);
          setIsLoading(false);
      } catch (error) {
        console.error("Error fetching current user:", error);
        setIsLoading(false);
      }
    };

    if (token) {
      fetchCurrentUser();
    } else {
      setIsLoading(false);
    }
  }, [API_ENDPOINT, token]);



  if (isLoading) {
    return null;
  }

  const renderRootRoute = () => {
    if (!isAuthenticated) {
      return <HomePage />;
    } else if (userType === 2 && !isAdminViewingAsStudent) {
      return <CoursesTab />;
    } else if (userType === 3 && !isParentViewingAsStudent) {
      return <ParentStudentsList />; // Student view for parents
    } else if (isParentViewingAsStudent) {
      return <FourYearPlan />; // Student view for parents
    } else {
      return <FourYearPlan />; // Default student view
    }
  };


  return (
    <div className="App">
      <Router>
      {!isAuthenticated ? <Navbar /> :
        (userType === 2 && schoolType === 'HS' && !isAdminViewingAsStudent) ? <HSNavbar2 /> :
        (userType === 2 && !isAdminViewingAsStudent) ? <Navbar2 /> :
        (userType === 3 && !isParentViewingAsStudent) ? <ParentNavbar /> :
        isAdminViewingAsStudent ? <AdminStudentNavbar /> :
        isParentViewingAsStudent ? <ParentStudentNavbar /> : // Parent viewing as student
        <StudentNavbar />}

        <Routes>  
        <Route path="/" element={renderRootRoute()} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/log-in" element={<LogIn />} />
          <Route path="/student-onboarding" element={<StudentOnboarding />} />
          <Route path="/admin-onboarding" element={<AdminOnboarding />} />
          <Route path="/course-page/:id" element={userType === 2 ? <CoursePage /> : <StudentCoursePage />} />
          <Route path="/blog-page/:id" element={<BlogPost />} />                    
          <Route path="/pathways/:id" element={userType === 2 ? <PathwayPage /> : <StudentPathwayPage />}  />
          <Route path="/pathways" element={userType === 2 ? <PathwaysTab /> : <StudentPathwaysTab />}  />
          <Route path="/courses" element={userType === 2 ? <CoursesTab /> : <StudentCoursesTab />} />
          <Route path="/partner-courses" element={<PartnerCoursesTab />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/plan" element={<FourYearPlan />} />
          <Route path="/students-list" element={<StudentsList />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:uidb64/:token" element={<ResetPassword />} />
          <Route path="/parent-students" element={<ParentStudentsList />} /> 
          <Route path="/onboarding-questions" element={<OnboardingQuestions />} />  
          <Route path="/onboarding-questions" element={<OnboardingQuestions />} />   
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />                
          <Route path="/test" element={<Test />} />                    
        </Routes>
      </Router>
    </div> 
  );  
}

export default App;
