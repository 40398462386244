import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Form } from 'react-bootstrap';
import Button from '../Button'; 
import Callout from '../Callout';
import { useNavigate } from 'react-router-dom';
import loadingGif from '../../assets/images/animation.gif';

function StudentsList() {
  const [isLoading, setIsLoading] = useState(true);
  const [students, setStudents] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'first_name', direction: 'asc' });
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredStudents, setFilteredStudents] = useState([]);
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await axios.get(`${API_ENDPOINT}/api/students-in-school/`, {
          headers: {
            'Authorization': `Token ${localStorage.getItem('token')}`
          }
        });
  
        let sortedStudents = response.data;
  
        // Apply default sorting by first name in ascending order
        sortedStudents.sort((a, b) => {
          if (a.first_name < b.first_name) return -1;
          if (a.first_name > b.first_name) return 1;
          return 0;
        });
  
        setStudents(sortedStudents);
        setFilteredStudents(sortedStudents); // Initialize filtered list with all students
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching students:", error);
      }
    };
  
    fetchStudents();
  }, [API_ENDPOINT]);

  
  const handleViewAsStudent = async (studentId) => {
    try {
      const adminToken = localStorage.getItem('token');
      const response = await axios.post(`${API_ENDPOINT}/api/view-as-student/`, { studentId }, {
        headers: {
          'Authorization': `Token ${adminToken}`
        }
      });
      localStorage.setItem('adminToken', adminToken);
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('isAdminViewingAsStudent', 'true');
      navigate('/plan');
      window.location.reload();
    } catch (error) {
      console.error("Error switching to student view:", error);
    }
  };


  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
  
    // If the search query is empty, show all students
    if (query === '') {
      setFilteredStudents(students);
      return;
    }
  
    // Otherwise, filter students based on the query
    const filtered = students.filter(
      (student) =>
        student.first_name.toLowerCase().includes(query) ||
        student.last_name.toLowerCase().includes(query) ||
        student.email.toLowerCase().includes(query)
    );
  
    setFilteredStudents(filtered);
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });

    const sortedStudents = [...filteredStudents].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      return 0;
    });

    setFilteredStudents(sortedStudents);
  };


  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? (
        <i className="fas fa-sort-up"></i>
      ) : (
        <i className="fas fa-sort-down"></i>
      );
    }
    return <i className="fas fa-sort"></i>;
  };

  const styles = {
    container: {
      width: '100%',
      backgroundColor: '#FFFFFF',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      // padding: '20px',
      marginBottom: '20px',
    },
    content: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: '20px',
    },
    h3: {
      marginTop: '0',
      marginBottom: '20px',
    },
    tableContainer: {
      width: '100%',
    },
    callout: {
      padding: '10px',
      marginBottom: '20px',
      backgroundColor: '#f8f9fa',
      borderLeft: '5px solid #007bff',
    },
    buttonsContainer: {
      display: 'flex',
      gap: '20px',
      marginTop: '20px',
    },
    
  };

  return (
    <div className="page-container">
      <div >
        <h1>Students List</h1>
        <Callout text="Below is the list of all students in your school. You can view and edit a student's Four Year Plan by clicking the View Student button." />

        {/* Search Bar */}
        <Form.Control
          type="text"
          placeholder="Search by name or email"
          value={searchQuery}
          onChange={handleSearch}
          style={{ marginBottom: '20px', maxWidth: '400px' }}
        />

        {isLoading ? (
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <img
              src={loadingGif}
              alt="Loading..."
              style={{ width: '100px', height: '100px', marginBottom: '0px' }}
            />
          </div>
        ) : (
          <div>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th onClick={() => handleSort('first_name')}>
                    First Name {getSortIcon('first_name')}
                  </th>
                  <th onClick={() => handleSort('last_name')}>
                    Last Name {getSortIcon('last_name')}
                  </th>
                  <th onClick={() => handleSort('email')}>
                    Email {getSortIcon('email')}
                  </th>
                  <th onClick={() => handleSort('grade')}>
                    Grade {getSortIcon('grade')}
                  </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredStudents.map((student) => (
                  console.log(student),
                  <tr key={student.id}>
                    <td>{student.first_name}</td>
                    <td>{student.last_name}</td>
                    <td>{student.email}</td>
                    <td>{student.grade}</td> {/* Grade Column */}
                    <td>
                      <Button
                        variant="primary"
                        onClick={() => handleViewAsStudent(student.id)}
                      >
                        View Student
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </div>
    </div>
  );
}

export default StudentsList;