import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown, faCheckCircle } from '@fortawesome/free-solid-svg-icons';


const CourseCard = ({ course }) => {
  const navigate = useNavigate();
  
  const styles = {

    tagContainer: {
      display: 'flex',
      gap: '8px',
      flexWrap: 'wrap',
    },
    tag: {
      backgroundColor: '#e0e0e0', // Fixed grey background
      color: '#333', // Default text color
      padding: '4px 8px',
      borderRadius: '4px',
      fontSize: '0.8em',
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
    },
    tagDot: {
      width: '8px',
      height: '8px',
      borderRadius: '50%',
    },
    
    modeTag: {
      backgroundColor: '#e0e0e0', // Light grey background, adjust as needed
      color: '#333', // Dark text color for contrast, adjust as needed
      padding: '2px 8px',
      borderRadius: '4px',
      display: 'inline-block', // Ensure it's treated as an inline element for layout
      fontSize: '0.8em', // Smaller font size for tag-like appearance
    },

    modeTagContainer: {
      backgroundColor: '#e0e0e0', // Light grey background
      color: '#333', // Text color
      padding: '2px 8px',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center', // Align the dot and text
      gap: '4px', // Space between the dot and text 
      fontSize: '0.8em', // Font size for the text
    },
    modeTagDot: {
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      backgroundColor: '#FF6347', // Dynamic color for the mode
    },

    table: {
      width: '100%',
      borderCollapse: 'collapse',
      marginTop: '16px',
    },
    tableHeader: {
      backgroundColor: '#f5f5f5',
      color: '#333',
      fontWeight: '600',
      textAlign: 'left',
      padding: '8px',
      borderBottom: '2px solid #ddd',
    },
    tableCell: {
      padding: '8px',
      borderBottom: '1px solid #ddd',
      verticalAlign: 'top',
      fontSize: '0.9em',
      lineHeight: '1.4',
    },


    strong: {
      display: 'block',
      margin: '5px 0',
    },
    card: {
      marginTop: '20px',
      width: '100%',
      padding: '20px',
      borderLeft: `8px solid #${course.school.primary_color}`,
      borderRadius: '0 10px 10px 0',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
      backgroundColor: '#FFFFFF',
      display: 'flex',
      cursor: 'pointer',
    },
    content: {
      marginLeft: '10px',
    },
    h3: {
      marginTop: '0',
    },
    schoolLink: {
      display: 'inline-block',
      margin: '10px 0',
      fontWeight: '600',
      color: '#00008A',
      textDecoration: 'none',
    },
    requirementsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '10px',
      marginTop: '10px',
      marginBottom: '10px',
    },
    requirement: {
      padding: '0px 10px',
      borderRadius: '5px',
      color: '#000',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    icon: {
      fontSize: '24px',
      alignSelf: 'flex-start',
    },
    statusContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    schoolAndModeContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px', // Adjust the space between the school name and the mode tag as needed
    },
    checkmark: {
      color: '#4CAF50',
      marginLeft: '10px',
    },

  };


  const handleCardClick = () => {
    navigate(`/course-page/${course.uuid}`);
  };



  // Function to process and group non-duplicable requirements while avoiding duplicates
  const processRequirements = () => {
    let displayReqs = [];
  
    // Updated structure to hold both names and colors
    const uniqueReqs = new Map();
  
    // Handle non-duplicable groups
    course.non_duplicables.forEach((nonDupGroup) => {
      const groupNames = nonDupGroup.requirements.map(req => req.name).join(' OR ');
      const groupColors = nonDupGroup.requirements.map(req => req.pathway_colors ? req.pathway_colors[0] : 'defaultColor');
      // Assuming the same color for all requirements in a group, or picking the first one's color
      const color = groupColors[0]; // This can be adjusted based on how you want to handle colors for grouped requirements
      // Add to the uniqueReqs map to ensure no duplicates, storing both name and color
      nonDupGroup.requirements.forEach(req => uniqueReqs.set(req.uuid, { name: groupNames, color: color, pathway: req.pathway_names[0] }));
    });
  
    // Add individual requirements if they are not part of any non-duplicable group
    course.requirements.forEach(req => {
      if (!uniqueReqs.has(req.uuid)) {
        const color = req.pathway_colors && req.pathway_colors[0] ? req.pathway_colors[0] : 'defaultColor';
        const pathwayName = req.pathway_names[0];
        uniqueReqs.set(req.uuid, { name: req.name, color: color, pathway: pathwayName });
      }
    });
  
    // Convert the unique requirements map to an array for rendering, preserving both name and color
    uniqueReqs.forEach((value, key) => {
      if (!displayReqs.find(req => req.name === value.name)) { // Ensure no duplicate groups are added
        displayReqs.push(value);
      }
    });
    return displayReqs;
  };

  const organizeDataByColor = () => {
    const groupedData = {};

    // Use units_info from course
    course.units_info.forEach((unitInfo) => {
      const color = unitInfo.primary_color || 'defaultColor';

      if (!groupedData[color]) {
        groupedData[color] = {
          school_name: unitInfo.school_name,
          units: `${unitInfo.units} units`,
          requirements: [],
        };
      }
    });

    // Add requirements to the group (if necessary, based on your existing logic)
    processRequirements().forEach((req) => {
      const color = req.color || 'defaultColor';

      if (!groupedData[color]) {
        groupedData[color] = { school_name: '', units: '', requirements: [] };
      }

      groupedData[color].requirements.push(`${req.pathway}: ${req.name}`);
    });

    return groupedData;
  };

  const groupedData = organizeDataByColor();
  
  return (
    <div onClick={handleCardClick} style={styles.card} >
      <div style={styles.content}>
        <h3 style={styles.h3}>
            {course.course_id + ": " + course.title}
            {/* {isUserCourse && <FontAwesomeIcon icon={faCheckCircle} style={styles.checkmark} />} */}
            {course.is_scheduled && (<FontAwesomeIcon icon={faCheckCircle} style={styles.checkmark} />)}
          </h3>
        <div style={styles.schoolAndModeContainer}>
          <strong style={styles.strong}>{course.school.name}</strong>        
        </div>        
        <p>{course.description}</p>

        {course.prerequisite_groups.length > 0 && (
        <p style={{ marginTop: "16px" }}>
          <strong>Prerequisites:</strong> {course.prerequisite_groups.join("; ")}
        </p>
      )}

        <div style={{ marginBottom: '20px' }}>
          <table style={styles.table}>
            {/* Table Header */}
            <thead>
              <tr>
                {Object.keys(groupedData).map((color, index) => (
                  <th key={index} style={styles.tableHeader}>
                    {`${groupedData[color].school_name}: ${groupedData[color].units}`}
                  </th>
                ))}
              </tr>
            </thead>

            {/* Table Body */}
            <tbody>
              {Array.from(
                { length: Math.max(...Object.values(groupedData).map((data) => data.requirements.length)) }
              ).map((_, rowIndex) => (
                <tr key={rowIndex}>
                  {Object.keys(groupedData).map((color, colIndex) => (
                    <td
                      key={colIndex}
                      style={{
                        ...styles.tableCell,
                        borderLeft: colIndex !== 0 ? "2px solid #ddd" : "none", // Divider between columns
                      }}
                    >
                      {groupedData[color].requirements[rowIndex] || ""}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {course.formatted_tags && (
        <div style={styles.tagContainer}>
          {course.formatted_tags.map((tag) => (
            <div key={tag.name} style={styles.tag}>
              <span style={{ ...styles.tagDot, backgroundColor: tag.color }}></span>
              <span>{tag.name}</span>
            </div>
          ))}
        </div>
      )}       
  
        
      </div>
    </div>
  );
  };

export default CourseCard;
