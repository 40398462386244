import React, { useState } from 'react';
import { Form, Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../Button'; // Assuming this is your custom button component
import TextField from '@mui/material/TextField';
import axios from 'axios';


function refreshUserData() {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const token = localStorage.getItem('token');

  return axios
    .get(`${API_ENDPOINT}/api/current-user/`, {
      headers: { Authorization: `Token ${token}` },
    })
    .then((response) => {
      localStorage.setItem('cachedUser', JSON.stringify(response.data));
    })
    .catch((error) => {
      console.error('Error refreshing user data:', error);
    });
}

function submitEdGoalsAndGeneratePlan(edGoals) {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const token = localStorage.getItem('token');

  return new Promise((resolve, reject) => {
    // Save educational goals
    axios
      .post(
        `${API_ENDPOINT}/api/update-ed-goals/`,
        { ed_goals: edGoals },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then(() => {
        console.log("Educational goals saved successfully.");

        // Trigger backend script to generate the plan
        axios
          .post(
            `${API_ENDPOINT}/api/generate-four-year-plan/`,
            {},
            {
              headers: {
                Authorization: `Token ${token}`,
              },
            }
          )
          .then(() => {
            console.log("Four-year plan generated successfully.");
            resolve(); // Resolve the promise after generating the plan
          })
          .catch((error) => {
            console.error("Error generating four-year plan:", error);
            reject(error);
          });
      })
      .catch((error) => {
        console.error("Error saving educational goals:", error);
        reject(error);
      });
  });
}

function StudentQuestions() {
  const navigate = useNavigate();

  // Define the questions array
  const questions = [
    {
      label: "What grade are you currently in?",
      type: "radio", // Use radio buttons for a single choice
      options: ["9th Grade", "10th Grade", "11th Grade", "12th Grade"],
    },
    {
      label: "Which of the following are you interested in while in high school?",
      type: "checkbox",
      options: [
        "Graduating from high school",
        "Taking advanced classes",
        "Earning credit towards AA degree",
        "Earning credit towards a bachelor's degree",
        "Earning the maximum college credit towards an AA degree or bachelor’s degree",
      ],
    },
    {
      label:
        "Have you completed any high school classes before entering high school? Check the box(es) representing any math or language classes you have taken before 9th grade:",
      type: "checkbox",
      options: [
        "Math I",
        "Math II",
        "Spanish I",
        "Spanish II",
        "Spanish Speakers I",
        "None of the above",
      ],
    },
  ];

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [responses, setResponses] = useState(Array(questions.length).fill(""));

  function handleNext(event) {
    event.preventDefault();
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      console.log("Final responses:", responses);

      // Wait for API calls to complete before navigating
      submitEdGoalsAndGeneratePlan(responses)
        .then(() => refreshUserData()) // Refresh user data
        .then(() => navigate('/plan')) // Navigate to the plan page
        .catch((error) => {
          console.error('Error during plan generation:', error);
        });
    }
  }

  function handleCheckboxChange(event, option) {
    const updatedResponses = [...responses];
    const currentResponses = updatedResponses[currentQuestion]
      ? updatedResponses[currentQuestion].split(", ")
      : [];

    if (event.target.checked) {
      currentResponses.push(option);
    } else {
      const index = currentResponses.indexOf(option);
      if (index > -1) currentResponses.splice(index, 1);
    }

    updatedResponses[currentQuestion] = currentResponses.join(", ");
    setResponses(updatedResponses);
  }

  function handleRadioChange(event) {
    const updatedResponses = [...responses];
    updatedResponses[currentQuestion] = event.target.value;
    setResponses(updatedResponses);
  }

  function handleTextChange(event) {
    const updatedResponses = [...responses];
    updatedResponses[currentQuestion] = event.target.value;
    setResponses(updatedResponses);
  }

  return (
    <div style={styles.signupPage}>
      <Container style={styles.signupContainer}>
        <Form>
          <div style={styles.onboardingSection}>
            <h2>Tell us about yourself</h2>
            <Form.Label style={styles.boldLabel}>
              {questions[currentQuestion].label}
            </Form.Label>

            {questions[currentQuestion].type === "checkbox" &&
              questions[currentQuestion].options.map((option, index) => (
                <Form.Check
                  key={index}
                  type="checkbox"
                  label={option}
                  checked={
                    responses[currentQuestion] &&
                    responses[currentQuestion].split(", ").includes(option)
                  }
                  onChange={(e) => handleCheckboxChange(e, option)}
                />
              ))}

        {questions[currentQuestion].type === "radio" &&
              questions[currentQuestion].options.map((option, index) => (
                <Form.Check
                  key={index}
                  type="radio"
                  label={option}
                  name="currentGrade" // Group radio buttons together
                  value={option}
                  checked={responses[currentQuestion] === option}
                  onChange={handleRadioChange}
                />
              ))}

            {questions[currentQuestion].type === "text" && (
              <TextField
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                placeholder={questions[currentQuestion].placeholder}
                value={responses[currentQuestion] || ""}
                onChange={handleTextChange}
              />
            )}
          </div>

          <div style={styles.centerText}>
            <Button
              variant="primary"
              onClick={handleNext}
              style={styles.customButton}
            >
              Next
            </Button>
          </div>
        </Form>
      </Container>
    </div>
  );
}

export default StudentQuestions;

// Inline styling
const styles = {
  signupPage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    padding: '20px',
  },
  signupContainer: {
    width: '100%',
    maxWidth: '400px',
    padding: '20px',
    margin: 'auto',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
    textAlign: 'left',
  },
  boldLabel: {
    fontWeight: 'bold',
  },
  centerText: {
    textAlign: 'center',
  },
  customButton: {
    padding: '10px 20px',
    backgroundColor: 'black',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  onboardingSection: {
    marginBottom: '30px',
  },
};