import React from 'react';
import { useDroppable } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import SortableCourse from './SortableCourse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

const DroppableColumn = ({ id, term, editablelabel, courses = [], title, onEditClick, disableDrag = false }) => {
  const { setNodeRef } = useDroppable({
    id,
  });

  const style = {
    backgroundColor: '#EAEAEA',
    padding: '5px',
    margin: '5px',
    borderRadius: '10px',
    minHeight: '200px',
    width: '200px',
    maxWidth: '300px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
  };

  const titleContainerStyle = {
    textAlign: 'center',
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const titleStyle = {
    fontWeight: 'bold', // Make the title bold
  };

  const iconStyle = {
    marginLeft: '10px',
    cursor: 'pointer',
  };

  return (
    <div ref={setNodeRef} style={style}>
      <div style={titleContainerStyle}>
        <h4 style={titleStyle}>{title}</h4>
        
        { disableDrag ? null : (
        <FontAwesomeIcon 
          icon={faEdit} 
          style={iconStyle} 
          onClick={() => onEditClick(editablelabel)} // Ensure term is passed here
        />        
        )}
      </div>
      <SortableContext items={courses.map((_, index) => `${term}|${index}`)} strategy={verticalListSortingStrategy}>
        {courses.map((course, index) => (
          <SortableCourse key={`${term}|${index}`} id={`${term}|${index}`} course={course.course} disableDrag={disableDrag}/>
        ))}
      </SortableContext>
    </div>
  );
};

export default DroppableColumn;