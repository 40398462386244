import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRoad } from '@fortawesome/free-solid-svg-icons';
import { faCircleChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Button from '../Button'; // Assuming you have a reusable Button component
import RequirementSection from './RequirementSection2';
import InputOverlay from '../InputOverlay'; // Adjust the path as necessary
import axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';


const CoursePage = () => {

  const styles = {

    tagContainer: { display: 'flex', gap: '8px', flexWrap: 'wrap', marginBottom: '16px' },
    tag: {
      backgroundColor: '#e0e0e0', // Fixed grey background
      color: '#333',
      padding: '4px 8px',
      borderRadius: '4px',
      fontSize: '0.8em',
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
    },
    tagDot: { width: '8px', height: '8px', borderRadius: '50%' },
    table: { width: '100%', marginTop: '16px', borderCollapse: 'collapse' },
    tableHeader: {
      border: "2px solid #ddd",
      backgroundColor: '#f5f5f5',
      fontWeight: '600',
      padding: '8px',
      borderBottom: '2px solid #ddd',
    },

    container: {
      width: '100%',
    //   marginBottom: '20px',
      backgroundColor: '#FFFFFF',
      display: 'flex',
      flexDirection: 'row', // Ensuring the children are laid out in a row
      alignItems: 'flex-start',
    },
    content: {
      marginLeft: '10px',
      display: 'flex',
      flexDirection: 'column', // Laying out the content in a column
      alignItems: 'flex-start', // Aligning content to the start
      marginBottom: '100px',

    },
    h3: {
      marginTop: '0',      
    },
    schoolLink: {
      display: 'inline-block',
      margin: '10px 0',
      fontWeight: '600',
      color: '#00008A', // Night Sky Blue
      textDecoration: 'none',
    },
    requirement: {
      padding: '5px 10px',
      borderRadius: '15px',
      color: '#FFFFFF',
    },
    icon: {
      fontSize: '36px',
      alignSelf: 'flex-start',
    },
    description: {
        textAlign: 'left', // Explicitly setting text alignment to left
      },

    topBar: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '50px',
    marginTop: '50px',
    },
    buttonsContainer: {
    display: 'flex',
    gap: '20px', // Add space between buttons
    },

    backButton: {
    fontSize: '40px',
    marginRight: '20px',
    cursor: 'pointer',
    },

    backButtonContainer: {
        cursor: 'pointer',
        transition: 'color 0.3s', // Smooth transition for color
        color: isHovered ? '#00008A' : 'black', // Night Sky Blue color on hover
      },

    iconContainer: {
        position: 'relative',
        display: 'inline-block',
        borderRadius: '50%',
        padding: '10px',
        backgroundColor: isHovered ? '#E6EFFF' : '#FFF', // Lighter blue background on hover
        color: isHovered ? '#00008A' : 'black', // Night Sky Blue color on hover
        transition: 'background-color 0.3s, color 0.3s', // Smooth transition for background-color and color
    },
    requirementsContainer: {
        display: 'flex',
        flexDirection: 'column', // Set the direction to column
        gap: '20px', // Set the gap between requirements
        marginTop: '10px',
        width: '100%',
      },
      requirementsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        marginTop: '10px',
        marginBottom: '10px', // Added bottom margin
      },
      requirement: {
          padding: '0px 10px',
          borderRadius: '5px',
          color: '#FFFFFF',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        modeTag: {
          backgroundColor: '#e0e0e0',
          color: '#333',
          padding: '2px 8px',
          borderRadius: '4px',
          display: 'inline-block',
          fontSize: '0.8em',
        },
        requirement: {
          padding: '0px 10px',
          borderRadius: '5px',
          color: '#000',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          // backgroundColor: '#FFE27B', // This color is for prerequisites in your CourseCard
        },
        strong: {
          display: 'block',
          margin: '5px 0',
        },
        requirementsContainer: {
          display: 'flex',
          flexWrap: 'wrap',
          gap: '10px',
          marginTop: '10px',
          marginBottom: '10px',
        },
        schoolAndModeContainer: {
          display: 'flex',
          alignItems: 'center',
          gap: '10px', // Adjust the space between the school name and the mode tag as needed
        },
    };

  const [isLoading, setIsLoading] = useState(true); 

  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [isHovered, setIsHovered] = React.useState(false);
  
  const { id } = useParams(); // Use the correct param name as used in your route
  const navigate = useNavigate();
  const [course, setCourse] = useState(null); // State to store the fetched course data
  const [allCourses, setAllCourses] = useState([]);
  const [selectedPrerequisites, setSelectedPrerequisites] = useState([]);
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const token = localStorage.getItem('token'); // Assuming you store the token in localStorage
  
  const [userSchool, setUserSchool] = useState(null);
  const [isExternalCourse, setIsExternalCourse] = useState(false);


  const [courseId, setCourseId] = useState('');
  const [courseTitle, setCourseTitle] = useState('');
  const [courseDescription, setCourseDescription] = useState('');
  const [courseUnits, setCourseUnits] = useState('');

  const [unitsTransferred, setUnitsTransferred] = useState('');
  const [isTransferable, setIsTransferable] = useState(false);

  const [isOverlayVisible2, setIsOverlayVisible2] = useState(false);

  const [prerequisiteGroups, setPrerequisiteGroups] = useState([[]]); // Prerequisite groups
  const [selectedTags, setSelectedTags] = useState([]); // Selected tags
  const tags = JSON.parse(localStorage.getItem('cachedUser')).school.tags || {};
  const tagOptions = Object.entries(tags).map(([key, value]) => ({ key, value })); // Tag options

  const [hsFullAccessEnabled, setHsFullAccessEnabled] = useState(JSON.parse(localStorage.getItem('cachedUser')).school.hs_full_access_enabled);

  

  useEffect(() => {
    const headers = {
      Authorization: `Token ${token}`,
    };
  
    const fetchAllCourses = async () => {
      try {
        const response = await axios.get(`${API_ENDPOINT}/api/courses/`, { headers });
        setAllCourses(response.data);
        return response.data;
      } catch (error) {
        console.error('Error fetching all courses:', error);
        return [];
      }
    };
  
    const fetchCurrentUserAndCourse = async (allCoursesData) => {
      try {
        const userResponse = await axios.get(`${API_ENDPOINT}/api/current-user/`, { headers });
        setUserSchool(userResponse.data.school);
  
        const response = await axios.get(`${API_ENDPOINT}/api/get-course/${id}/`, { headers });
        const courseData = response.data;
        setCourse(courseData);
    
        // Initialize form fields with fetched course data
        setCourseId(courseData.course_id);
        setCourseTitle(courseData.title);
        setCourseDescription(courseData.description);
        setCourseUnits(courseData.units);

        // Populate prerequisite groups properly
        if (courseData.prerequisite_groups_uuids) {
          const populatedGroups = courseData.prerequisite_groups_uuids.map(group =>
            group
              .map(uuid => allCoursesData.find(course => course.uuid === uuid))
              .filter(Boolean) // Ensure no undefined values
          );

          setPrerequisiteGroups(populatedGroups); // Set properly formatted groups
        }

        // Populate tags into selectedTags
        if (courseData.tags) {
          const formattedTags = Object.entries(courseData.tags).map(([key, value]) => ({ key, value }));
          setSelectedTags(formattedTags);
        }

  
        const isExternal = courseData.school.uuid !== userResponse.data.school.uuid;
        setIsExternalCourse(isExternal);
  
        // If the course is external, check its transferability
        if (isExternal) {
          try {
            const transferabilityResponse = await axios.get(`${API_ENDPOINT}/api/check-transferability/${id}/${userResponse.data.school.uuid}`, {
              headers: { Authorization: `Token ${token}` },
            });
            setIsTransferable(transferabilityResponse.data.isTransferable);
          } catch (error) {
            console.error("Error checking transferability:", error);
          }
        }
  
        // Prepopulate selected prerequisites using course_id instead of uuid
        const existingPrerequisites = courseData.prerequisites.map(prerequisite => {
          const matchedCourse = allCoursesData.find(course => course.course_id === prerequisite.course_id);
          return matchedCourse;
        }).filter(course => course !== undefined); // Filter out any undefined courses
  
        setSelectedPrerequisites(existingPrerequisites);
  
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching current user or course:", error);
        setIsLoading(false);
      }
    };
  
    if (token && id) {
      fetchAllCourses().then(allCoursesData => {
        fetchCurrentUserAndCourse(allCoursesData);
      });
    }
  }, [id, token]);


  const handleRemoveTransferability = async () => {
    const confirmRemove = window.confirm("Are you sure you want to remove transferability?");
    if (!confirmRemove) return;
  
    try {
      await axios.delete(`${API_ENDPOINT}/api/remove-transferability/`, {
        data: { original_course_id: id, accepting_school_id: userSchool.uuid }, // Adjust as necessary
        headers: { Authorization: `Token ${token}` },
      });
      setIsTransferable(false); // Update state to reflect change
      alert("Transferability removed successfully.");
    } catch (error) {
      console.error("Error removing transferability:", error);
      alert("Failed to remove transferability.");
    }
  };
    
  const toggleOverlay = () => {
    if (isOverlayVisible) {
      // Reset prerequisite groups or default to one empty group with one field
      setPrerequisiteGroups(
        course.prerequisite_groups_uuids && course.prerequisite_groups_uuids.length > 0
          ? course.prerequisite_groups_uuids.map(group =>
              group.map(uuid => allCourses.find(course => course.uuid === uuid)).filter(Boolean)
            )
          : [[]] // Default to one empty group
      );
  
      // Reset tags to existing data or empty array if none exist
      setSelectedTags(
        course.tags ? Object.entries(course.tags).map(([key, value]) => ({ key, value })) : []
      );
    }
  
    setIsOverlayVisible(!isOverlayVisible);
  };

  
    
  const toggleOverlay2 = () => {
    setIsOverlayVisible2(!isOverlayVisible2);
  };

  const deleteCourse = async () => {
    const confirmDelete = window.confirm("Are you sure you want to delete this course?");
    if (!confirmDelete) {
      return; // Stop the function if the user cancels
    }
  
    try {
      const headers = {
        Authorization: `Token ${token}`, // Use your existing token
        'Content-Type': 'application/json',
      };
      await axios.delete(`${API_ENDPOINT}/api/courses/${id}/`, { headers });
      // Show a success message if needed
      setIsOverlayVisible(false);
      navigate('/courses');
    } catch (error) {
      console.error('Error deleting course:', error);
      // Handle error (e.g., show an error message)
    }
  };

  if (isLoading) {
    return <div>Loading...</div>; // Show a loading message or a spinner
  }

  const handleAddGroup = (event) => {
    event.preventDefault();
    setPrerequisiteGroups([...prerequisiteGroups, []]); // Adds a new empty group
  };
  
  const handleCourseSelection = (index, newValue) => {
    const updatedGroups = [...prerequisiteGroups];
    updatedGroups[index] = newValue; // Store the full course objects
    setPrerequisiteGroups(updatedGroups);
  };



  const saveCourseDetails = async (e) => {
    e.preventDefault();

    const formattedPrerequisiteGroups = prerequisiteGroups.map(group =>
      group.map(course => course.uuid) // Extract only UUIDs from the course objects
    );
  
    const updatedCourseData = {
      course_id: courseId,
      title: courseTitle,
      description: courseDescription,
      units: courseUnits,
      update_prerequisite_groups: formattedPrerequisiteGroups, // Include groups as an array
      tags: selectedTags.reduce((acc, tag) => {
        acc[tag.key] = tag.value;
        return acc;
      }, {}),
    };
  
    try {
      const response = await axios.post(
        `${API_ENDPOINT}/api/courses/${id}/`,
        updatedCourseData,
        { headers: { Authorization: `Token ${token}` } }
      );
  
      setCourse(response.data);
      setIsOverlayVisible(false);
    } catch (error) {
      console.error('Error saving course:', error);
    }
  };

  const courseForm = (
    <form onSubmit={saveCourseDetails}>
      <div className="mb-3">
        <label style={{ fontWeight: 'bold' }} htmlFor="courseId" className="form-label">Course ID</label>
        <input 
          type="text"
          className="form-control"
          id="courseId"
          name="courseId"
          value={courseId}
          onChange={(e) => setCourseId(e.target.value)}
        />
      </div>

      <div className="mb-3">
        <label style={{ fontWeight: 'bold' }} htmlFor="courseTitle" className="form-label">Course Title</label>
        <input
          type="text"
          className="form-control"
          id="courseTitle"
          value={courseTitle}
          onChange={(e) => setCourseTitle(e.target.value)}
        />
      </div>

      <div className="mb-3">
        <label style={{ fontWeight: 'bold' }} htmlFor="courseDescription" className="form-label">Course Description</label>
        <textarea
          className="form-control"
          id="courseDescription"
          rows="3"
          value={courseDescription}
          onChange={(e) => setCourseDescription(e.target.value)}
        />
      </div>

      <div className="mb-3">
        <label htmlFor="courseUnits" className="form-label" style={{ fontWeight: 'bold' }}>Units</label>
        <input
          type="number"
          className="form-control"
          id="courseUnits"
          value={courseUnits}
          onChange={(e) => setCourseUnits(e.target.value)}
        />
      </div>

      {/* Prerequisite Groups */}
      <div className="mb-3">
        <label htmlFor="prerequisites" className="form-label">Prerequisite Groups</label>
        {prerequisiteGroups.length === 0 && setPrerequisiteGroups([[]]) /* Default to one empty group */}
        {prerequisiteGroups.map((group, index) => (
          <Autocomplete
            key={index}
            multiple
            options={allCourses}
            getOptionLabel={(option) => `${option.course_id}: ${option.title}`}
            value={group}
            onChange={(event, newValue) => handleCourseSelection(index, newValue)}
            renderInput={(params) => (
              <TextField {...params} label={`Group ${index + 1}`} variant="outlined" />
            )}
            style={{ marginBottom: '10px' }}
          />
        ))}
        <Button onClick={handleAddGroup}>+</Button>
      </div>

      {/* Tags */}
      <div className="mb-3">
        <label htmlFor="tags" className="form-label">Tags</label>
        <Autocomplete
          multiple
          options={tagOptions}
          getOptionLabel={(option) => option.key}
          onChange={(event, newValue) => setSelectedTags(newValue)}
          renderInput={(params) => (
            <TextField {...params} label="Tags" variant="outlined" />
          )}
          value={selectedTags}
        />
      </div>

      
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Button type="submit" className="btn btn-primary">Save Course Details</Button>
        <Button onClick={deleteCourse} style={{ backgroundColor: 'red', color: 'white' }}>Delete Course</Button>
      </div>
    </form>
  );


const handleTransferabilitySubmit = async (e) => {
  e.preventDefault();

  const transferData = {
    original_course_id: id, // Assuming 'id' is the ID of the course to make transferable
    accepting_school_id: userSchool.uuid, // The accepting school's ID
    adjusted_units: unitsTransferred
  };

  try {
    const response = await axios.post(`${API_ENDPOINT}/api/transfer-course/`, transferData, {
      headers: {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/json',
      },
    });

    setIsOverlayVisible2(false); // Close the overlay
    setIsTransferable(true);
    // Optionally, handle success, e.g., displaying a message or updating state
  } catch (error) {
    console.error('Error submitting transferability:', error);
    // Optionally, handle errors, e.g., displaying an error message
  }
};


const transferabilityForm = (
  <form onSubmit={handleTransferabilitySubmit}>
      <div className="mb-3">
          <label htmlFor="unitsTransferred" className="form-label" style={{ fontWeight: 'bold' }}>Unit Value</label>
          <input 
            type="number" 
            className="form-control" 
            id="unitsTransferred" 
            value={unitsTransferred}
            onChange={(e) => setUnitsTransferred(e.target.value)} 
          />
      </div>

      <Button type="submit" className="btn btn-primary">Accept Transferability</Button>
  </form>
);


  const processRequirements = () => {
    let displayReqs = [];
  
    // Updated structure to hold both names and colors
    const uniqueReqs = new Map();
  
    // Handle non-duplicable groups
    course.non_duplicables.forEach((nonDupGroup) => {
      const groupNames = nonDupGroup.requirements.map(req => req.name).join(' OR ');
      const groupColors = nonDupGroup.requirements.map(req => req.pathway_colors ? req.pathway_colors[0] : 'defaultColor');
      // Assuming the same color for all requirements in a group, or picking the first one's color
      const color = groupColors[0]; // This can be adjusted based on how you want to handle colors for grouped requirements
      // Add to the uniqueReqs map to ensure no duplicates, storing both name and color
      nonDupGroup.requirements.forEach(req => uniqueReqs.set(req.uuid, { name: groupNames, color: color, pathway: req.pathway_names[0] }));
    });
  
    // Add individual requirements if they are not part of any non-duplicable group
    course.requirements.forEach(req => {
      if (!uniqueReqs.has(req.uuid)) {
        const color = req.pathway_colors && req.pathway_colors[0] ? req.pathway_colors[0] : 'defaultColor';
        const pathwayName = req.pathway_names[0];
        uniqueReqs.set(req.uuid, { name: req.name, color: color, pathway: pathwayName });
      }
    });
  
    // Convert the unique requirements map to an array for rendering, preserving both name and color
    uniqueReqs.forEach((value, key) => {
      if (!displayReqs.find(req => req.name === value.name)) { // Ensure no duplicate groups are added
        displayReqs.push(value);
      }
    });
  
    return displayReqs;
  };

  const organizeDataByColor = () => {
    const groupedData = {};

    // Use units_info from course
    course.units_info.forEach((unitInfo) => {
      const color = unitInfo.primary_color || 'defaultColor';

      if (!groupedData[color]) {
        groupedData[color] = {
          school_name: unitInfo.school_name,
          units: `${unitInfo.units} units`,
          requirements: [],
        };
      }
    });

    // Add requirements to the group (if necessary, based on your existing logic)
    processRequirements().forEach((req) => {
      const color = req.color || 'defaultColor';

      if (!groupedData[color]) {
        groupedData[color] = { school_name: '', units: '', requirements: [] };
      }

      groupedData[color].requirements.push(`${req.pathway}: ${req.name}`);
    });

    return groupedData;
  };

  const groupedData = organizeDataByColor();

  return (
    <div className="page-container">
      {isOverlayVisible && (
        <InputOverlay
            title="Edit Course"
            description="Please edit the details of the course below."
            onClose={toggleOverlay}
            content={courseForm}
        />
      )}
      {isOverlayVisible2 && (
        <InputOverlay
          title="Accept Transferability"
          description="Please enter how many units you would like this course to be worth in your school. Once you accept transferablility, your students will be able to see this course as available for them."
          onClose={toggleOverlay2}
          content={transferabilityForm}
        />
      )}

    <div style={ styles.container }>        
      <div style={styles.content}>        
        <h1 style={styles.h3}>{course.course_id + ": " + course.title}</h1>
        <div style={styles.schoolAndModeContainer}>
          <h3 style={styles.strong}>{course.school.name}</h3>        
        </div>
        <p style={styles.description}>{course.description}</p>

        {/* Prerequisites */}
        {course.prerequisite_groups.length > 0 && (
        <p style={{ marginTop: "16px" }}>
          <strong>Prerequisites:</strong> {course.prerequisite_groups.join("; ")}
        </p>
      )}

      {/* Transferability Table */}

        <div style={{ marginBottom: '20px' }}>
          <table style={styles.table}>
            {/* Table Header */}
            <thead>
              <tr>
                {Object.keys(groupedData).map((color, index) => (
                  <th key={index} style={styles.tableHeader}>
                    {`${groupedData[color].school_name}: ${groupedData[color].units}`}
                  </th>
                ))}
              </tr>
            </thead>

            {/* Table Body */}
            <tbody>
              {Array.from(
                { length: Math.max(...Object.values(groupedData).map((data) => data.requirements.length)) }
              ).map((_, rowIndex) => (
                <tr key={rowIndex}>
                  {Object.keys(groupedData).map((color, colIndex) => (
                    <td
                      key={colIndex}
                      style={{
                        ...styles.tableCell,
                        border: "2px solid #ddd", // Divider between columns,
                        padding: "5px",
                      }}
                    >
                      {groupedData[color].requirements[rowIndex] || ""}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>


        {/* Tags */}
        {course.formatted_tags && (
        <div style={styles.tagContainer}>
          {course.formatted_tags.map((tag) => (
            <div key={tag.name} style={styles.tag}>
              <span style={{ ...styles.tagDot, backgroundColor: tag.color }}></span>
              <span>{tag.name}</span>
            </div>
          ))}
        </div>
      )}


        <div style={styles.buttonsContainer}>
        {hsFullAccessEnabled && (
          <Button onClick={toggleOverlay}>Edit Course Details</Button>
        )}
          {isExternalCourse && (
            <>
              {isTransferable ? (
                <Button onClick={handleRemoveTransferability}>Remove Transferability</Button>
              ) : (
                <Button onClick={toggleOverlay2}>Accept Transferability</Button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
    
    </div>
  );
}

export default CoursePage;
  
